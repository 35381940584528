<template>
  <section v-loading="isLoading" class="product-page">
    <div class="page-header xa-cell">
      <div class="search-box">
        <el-input
          placeholder="产品名称"
          v-on:keyup.enter.native="onSearchQuery(true)"
          v-model="query.keyword"
          size="small"
        >
          <i
            @click="onSearchQuery(true)"
            slot="prefix"
            :class="{ 'xa-color-info': !!query.keyword }"
            class="el-input__icon el-icon-search"
          ></i>
          <i
            v-show="query.keyword"
            @click="query.keyword = ''"
            slot="suffix"
            class="el-input__icon el-icon-close"
          ></i>
        </el-input>
      </div>
      <div
        class="filter-box xa-cell xa-flex"
        style="margin-left:16px;margin-right:16px"
      >
        <span style="margin-right:8px;font-size:14px;">产品分类</span>
        <el-cascader
          class="xa-flex"
          v-model="query.selectedOptions"
          placeholder="产品分类"
          :options="filterOptions"
          checkStrictly
          size="small"
        ></el-cascader>
      </div>
      <div class="btns-box">
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="onSearchQuery"
          >搜索</el-button
        >
        <el-button
          icon="el-icon-remove-outline"
          size="small"
          @click="onResetQuery"
          >重置</el-button
        >
      </div>
    </div>
    <div class="page-table">
      <el-table
        ref="multipleTable"
        :highlight-current-row="!multipSelect"
        height="360"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @current-change="handleCurrentTableChange"
      >
        <el-table-column type="index" width="50" />
        <el-table-column v-if="multipSelect" type="selection" width="55" />
        <el-table-column label="产品">
          <template slot-scope="scope">
            <div class="xa-cell">
              <div
                @click="onPriview(scope.row.logo_url)"
                class="img-wrap"
                :style="{ backgroundImage: 'url(' + scope.row.first_pic + ')' }"
              ></div>
              {{ scope.row.title }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="category_name" label="产品分类" width="120" />
      </el-table>
    </div>
    <div class="page-pagination xa-cell">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.page_index"
        :page-sizes="[10, 20, 30]"
        :page-size="query.page_size"
        layout="total, sizes, prev, pager, next"
        :total="totalDataCount"
      />
    </div>
    <div class="page-btns xa-cell">
      <span v-if="multipSelect" style="font-weight: bold;margin-right:16px;"
        >已选{{ multipleSelection.length }}</span
      >
      <span v-if="currentRow" style="font-weight: bold;margin-right:16px;"
        >已选:{{ currentRow.title }}</span
      >
      <el-button
        :disabled="multipleSelection.length == 0 && !currentRow"
        type="primary"
        size="small"
        @click="onSubimt"
        >确定</el-button
      >
      <el-button size="small" @click="onClose">取消</el-button>
    </div>
  </section>
</template>
<script>
import { getCategoryListOptions, getSearchProduct } from '@target/apis/mall'
export default {
  props: {
    params: {
      type: Object,
      default() {
        return {}
      },
    },
    selectType: {
      type: String,
      default: 'checkbox',
    },
    multipSelect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoading: true,
      totalDataCount: 0,
      query: {
        page_index: 1,
        page_size: 10,
        keyword: '',
        selectedOptions: [],
      },
      filterOptions: [],
      tableData: [],
      multipleSelection: [],
      currentRow: null,
    }
  },
  methods: {
    onSubimt() {
      if (this.multipleSelection.length || this.currentRow) {
        this.$emit('submit', this.currentRow || this.multipleSelection)
        this.onClose()
        this.multipleSelection = []
        this.currentRow = null
        this.$refs.multipleTable.clearSelection()
      }
    },
    onClose() {
      this.$emit('close')
    },
    handleSizeChange(val) {
      this.query.page_size = val
      this.onSearchQuery()
    },
    handleCurrentChange(val) {
      this.query.page_index = val
      this.onSearchQuery()
    },
    handleCurrentTableChange(val) {
      if (!this.multipSelect) {
        this.currentRow = val
      }
    },
    onSearchQuery(isFormSearch) {
      if (isFormSearch) {
        this.query.page_index = 1
      }
      let params = {
        page_index: this.query.page_index,
        page_size: this.query.page_size,
        keyword: this.query.keyword,
        category_guid: this.query.selectedOptions.length
          ? this.query.selectedOptions[this.query.selectedOptions.length - 1]
          : '',
      }
      this.queryData(params)
    },
    onResetQuery() {
      this.query.keyword = ''
      this.query.selectedOptions = []
      this.onSearchQuery()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    async queryData(params = {}) {
      this.isLoading = true
      Object.assign(params, this.params)
      const searchData = await getSearchProduct(params)
      this.tableData = searchData.data
      this.totalDataCount = parseInt(searchData.recordCount)
      this.isLoading = false
    },
  },
  async created() {
    try {
      const data = await getCategoryListOptions(this.params)
      this.filterOptions = data
      this.queryData()
    } finally {
      this.isLoading = false
    }
  },
}
</script>
<style lang="scss" scoped>
.img-wrap {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border: 1px dotted #ccc;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 4px;
  cursor: pointer;
}
.page-header {
  padding: 8px 0;
  border-bottom: 1px solid #e4e4e4;
}
.page-table {
  padding: 16px;
}
.page-pagination {
  padding: 8px 0;
}
.page-btns {
  border-top: 1px solid #e4e4e4;
  padding: 8px 0;
  justify-content: flex-end;
}
</style>
